import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div class="footermenu">
        <ul>
            <li className="sitemap">What's VRAA</li>
                <Link to='/vraa'><li>What`s VRAA</li></Link>
                <Link to='/staff'><li>運営スタッフ</li></Link>
        </ul>
        <ul>
            <li className="sitemap">contents</li>
                <Link to='/'><li>サイトTOP</li></Link>
                <li><Link to="/">全提出作品リスト</Link></li>
                <Link to='/outline'><li>概要開催</li></Link>
                <Link to='/virtualsite'><li>VRAA Virtual Site</li></Link>
                <Link to='/movie'><li>配信動画</li></Link>
                <a href="https://xrarchi.org/vraa1/vraa-girl/" target="_blank"><li>VRAA Girl</li></a>
        </ul>
        <ul>
            <li className="sitemap">規約／ガイドライン</li>
                <Link to='/rule'><li>応募規約／プライバシーポリシー</li></Link>
                <Link to='/qa'><li>よくある質問</li></Link>
        </ul>
        <ul>
            <li className="sitemap">関連サイト</li>
                <a href='http://xrc.or.jp/award2020/' target='_blank'><li>XR CREATIVE AWARD 2020</li></a>
                <a href='https://xrarchi.org/vraa1/' target='_blank'><li>VRAA01</li></a>
            </ul>
            </div>
        <p className="copyright">&copy;VRAA Design: <a href="https://html5up.net">HTML5 UP</a>. Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a></p>
    </footer>
)


export default Footer
