import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Virtualsite from '../images/virtualsite.png'

class VirtualSitePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="virtualsite">
                        <img src={Virtualsite} width="100%"/>
                        <div className="virtualsitetext">
                            <div className="box1"><a href="https://vrchat.com/home/launch?worldId=wrld_146fa475-7ef8-49fa-aa16-b158471342de&instanceId=9157&shortName=michael-wabbitivy-42b6b" target="_blank"><button className="Virtualsitebutton">VRAA Virtual Siteへ行く（VRChat）</button></a></div>
                            <div className="box2"><span className="virtualsitetextdetail">「VRAA Virtual Site」はバーチャル空間に設置されたVRAAの情報を伝えるためのHomeWorldです。</span><br />
                            <span className="virtualsitetextdetail">InternetにおけるWebsiteのように、Virtual世界のArchitectureに関する様々な情報を集積していくVirtualな“場”＝Siteとなることを目指しています。</span><br />
                            <span className="virtualsitetextdetail">ぜひFavorite登録していただき、Virtual Siteへお越しください！</span><br /><br />
                            <span className="virtualsitetextdetail">制作はワールドクリエーターの三日坊主、運用と更新はVRAAチームにて行っていきます。</span><br />
                            <span className="virtualsitetextdetail">現在、VRAA01へのポータルや、VRAA02の参加情報・スライドなどを設置しています。</span><br />
                                <span className="virtualsitetextdetail">今後、Websiteと合わせて、Virtual Siteもアワードの情報を随時更新していきますのでお楽しみに！</span>
                            </div>
                        </div>
                        
                    </div>
                    <div id="wrapper-footer">
                        <Footer timeout={this.state.timeout} />
                    </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA VirtualSite</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default VirtualSitePage
